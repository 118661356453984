import React from 'react';
import './App.scss';

function App() {

    const [lowResLoaded, setLowResLoaded] = React.useState(false);

    return (
        <div className="App">
            <div className={"RootBackground"}>
                <img id={'bg_low_res'} src={'/cosmos_low_resolution.png'} alt={"cosmos"} onLoad={()=>{ setLowResLoaded(true); }}/>
                <img id={'bg_high_res'} src={lowResLoaded?'/cosmos.png':''} style={{ opacity: lowResLoaded?1:0, }} alt={"cosmos"}/>
            </div>
            <div className='Body'>
                <div className={"Content"}>
                    <div style={{fontFamily: 'sacramentoregular', color: 'white', fontSize: '4rem', fontStyle: 'italic'}}>
                        <span style={{fontWeight: 'bold'}}>{"only two things are infinite;"}</span><br/>
                        <span>{"the universe and human stupidity, and i'm not sure about the former"}</span>
                    </div>
                </div>
            </div>
            <footer>
                <a href="mailto:today.zip.domain@gmail.com">{"today.zip.domain@gmail.com"}</a>
            </footer>
        </div>
    );
}

export default App;
